import React, { PropsWithChildren } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import enhanceMessage from './enhanceMessage';
import { TwilioError } from 'twilio-video';
import Swal from 'sweetalert2';

interface ErrorDialogProps {
  dismissError: Function;
  error: TwilioError | null;
}

function ErrorDialog({ dismissError, error }: PropsWithChildren<ErrorDialogProps>) {
  const history = useHistory();

  const { message, code } = error || {};
  const enhancedMessage = enhanceMessage(message, code);

  /**
   * Custom Intervention
   *
   * Validate if error is related to Room Completed (code 53118).
   * This error may be raised if the doctor finishes the appointment before
   * user leaves the room. In this case, we need to show an appropriate message
   * and redirect user to finish page.
   */
  if (code === 53118) {
    Swal.fire('Encerramento de consulta', 'O médico encerrou a sala de consulta virtual', 'info').then(() =>
      history.push('/finish')
    );
    return null;
  }

  return (
    <Dialog open={error !== null} onClose={() => dismissError()} fullWidth={true} maxWidth="xs">
      <DialogTitle>ERROR</DialogTitle>
      <DialogContent>
        <DialogContentText>{enhancedMessage}</DialogContentText>
        {code && (
          <pre>
            <code>Error Code: {code}</code>
          </pre>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dismissError()} color="primary" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ErrorDialog;
