import React from 'react';
import ParticipantStrip from '../ParticipantStrip/ParticipantStrip';
import { styled } from '@material-ui/core/styles';
import MainParticipant from '../MainParticipant/MainParticipant';
import { isMobile } from '../../utils';
// import useLocalVideoToggle from '../../hooks/useLocalVideoToggle/useLocalVideoToggle';

const Container = styled('div')(({ theme }) => ({
  position: 'relative',
  height: '100%',
  display: 'grid',
  gridTemplateColumns: `${isMobile ? `0` : theme.sidebarWidth}px 1fr`,
  // gridTemplateColumns: `0px 1fr`, // this option hides the participants strip
  // gridTemplateColumns: `${theme.sidebarWidth}px 1fr`,
  gridTemplateAreas: '". participantList"',
  [theme.breakpoints.down('xs')]: {
    gridTemplateAreas: '"participantList" "."',
    gridTemplateColumns: `auto`,
    gridTemplateRows: `calc(100% - ${theme.sidebarMobileHeight + 12}px) ${theme.sidebarMobileHeight + 6}px`,
    gridGap: '6px',
  },
}));

export default function Room() {
  // const [, toggleVideoEnabled] = useLocalVideoToggle();

  // const toggleVideo = useCallback(() => {
  //   toggleVideoEnabled();
  // }, [toggleVideoEnabled]);

  // const togglerManager = useCallback(() => {
  //   console.log('fetch refresh value');
  //   const refresh = localStorage.getItem('@refresh');
  //   console.log('refresh value: ', refresh);

  //   if (!refresh) {
  //     return;
  //   }

  //   console.log('refresh value: ', refresh);
  //   console.log('toggleAction started');

  //   localStorage.removeItem('@refresh');
  //   console.log('localStorage cleared');

  //   // se ligado, desliga e liga
  //   const interval = setInterval(() => {
  //     console.log('Was enabled, set disabled');
  //     toggleVideo();
  //     setInterval(() => {
  //       console.log('And set enabled.');
  //       toggleVideo();
  //     }, 1500);
  //   }, 5000);

  //   return clearInterval(interval);
  // }, [toggleVideo]);

  // useEffect(() => togglerManager(), [togglerManager]);

  return (
    <Container>
      <ParticipantStrip />
      <MainParticipant />
    </Container>
  );
}
