import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import logoConecta from './logo-conecta.svg';

import CircularProgress from '@material-ui/core/CircularProgress';

import { format } from 'date-fns';
// import { utcToZonedTime } from 'date-fns-tz';

import pt from 'date-fns/locale/pt-BR/index.js';

const useStyles = makeStyles({
  container: {
    height: '100vh',
    background: '#FFF',
  },
  header: {
    height: 64,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  banner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: 186,
    background: 'linear-gradient(225deg, #75F0A0 0%, #4BACE5 100%)',
    boxShadow: 'inset 0 20px 20px -25px rgba(0,0,0,0.8)',
    color: '#FFF',
    '& h1': {
      color: '#FFF',
      fontSize: 32,
      fontWeight: 'bold',
      textAlign: 'center',
    },
    '& p': {
      fontSize: 16,
      textAlign: 'center',
    },
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    maxWidth: 818,
    padding: 20,
  },

  doctorCard: {
    display: 'flex',
    flexDirection: 'row',

    alignItems: 'center',
    justifyContent: 'space-between',

    padding: '12px 19px',

    background: '#F4F5F7',
    boxShadow: '0 8px 10px 0 rgba(0,0,0,0.21)',
    borderRadius: 10,

    width: '90%',
    maxWidth: 524,

    marginTop: 50,

    color: '#4A4A4A',

    '@media (max-width: 460px)': {
      boxShadow: 'none',
      background: 'transparent',
    },
  },

  doctorCardImage: {
    objectFit: 'cover',
    borderRadius: 4,
    height: 108,
  },

  doctorCardData: {
    margin: 0,
    width: '100%',
    textAlign: 'left',
    paddingLeft: 21,

    '& .name': {
      fontSize: 24,
      fontWeight: 'bold',
      margin: 0,
      '@media (max-width: 440px)': {
        fontSize: 20,
      },
      '@media (max-width: 400px)': {
        fontSize: 18,
      },
      '@media (max-width: 380px)': {
        fontSize: 16,
      },
    },

    '& .specialty': {
      fontSize: 16,
      margin: 0,
    },

    '& .address': {
      fontSize: 12,
      color: '#8E8E92',
      margin: 0,
    },

    '& .specialty-label': {
      '@media (min-width: 460px)': {
        display: 'none',
      },
      display: 'initial',
      background: '#4267B2',
      padding: '2px 4px',
      borderRadius: 4,
      color: '#FFF',
    },

    '& .crm': {
      '@media (min-width: 460px)': {
        display: 'none',
      },
      color: '#8E8E92',
      fontSize: 11,
      textAlign: 'left',
    },
  },

  doctorCardSpecialty: {
    '@media (max-width: 460px)': {
      display: 'none',
    },

    display: 'flex',
    flexDirection: 'column',

    '& .specialty': {
      background: '#4267B2',
      padding: '2px 4px',
      borderRadius: 4,
      color: '#FFF',
    },

    '& p': {
      color: '#8E8E92',
      fontSize: 11,
      textAlign: 'left',
    },
  },

  information: {
    marginTop: 52,
    color: '#4A4A4A',
    fontSize: 16,
    textAlign: 'center',
    '& p + p': {
      fontWeight: 'bold',
    },
  },

  actionButtons: {
    marginTop: 100,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  cancelButton: {
    border: 0,
    width: 312,
    height: 48,
    borderRadius: 24,
    background: '#F85359',
    color: '#FFF',
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 'bold',
    cursor: 'pointer',
  },

  linkButton: {
    marginTop: 29,
    color: '#0664BF',
    fontSize: 14,
    fontWeight: 'bold',
  },

  errorMessage: {
    color: 'red',
    display: 'flex',
    alignItems: 'center',
    margin: '1em 0 0.2em',
    '& svg': {
      marginRight: '0.4em',
    },
  },
});

const theme = createMuiTheme({
  palette: {
    type: 'light',
  },
});

export default function EntryPage() {
  const history = useHistory();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [hasProfilePicture, setHasProfilePicture] = useState(false);
  const [appointmentData, setAppointmentData] = useState(null);
  const baseURL = process.env.REACT_APP_TOKEN_ENDPOINT;
  const uuid = localStorage.getItem('@uuid');
  const [partnerData] = useState(() => {
    const partnerStringData = localStorage.getItem('@partner');
    if (!partnerStringData) return null;
    return JSON.parse(partnerStringData);
  });

  const fetchAppointmentData = useCallback(() => {
    if (loading || appointmentData) return;

    if (!uuid) {
      history.push('/not_found');
      return;
    }

    setLoading(true);

    fetch(`${baseURL}/telemedicine/agenda/${uuid}`)
      .then(response => {
        if (response.status < 400) {
          response.json().then(res => {
            const date = new Date(res.appointment_start);
            // const znDate = utcToZonedTime(date, 'UTC');

            // https://date-fns.org/v2.11.1/docs/format
            const formattedDate = format(date, "EEEE, dd'/'MM' às 'HH:mm", {
              locale: pt,
            });

            res.stringDate = formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
            res.doctorProfilePicture = `${baseURL}/s3/file/doctor/${res.doctor.id}/profile_picture`;
            res.defaultProfilePicture =
              res.doctor.user.gender === 'F' ? require('./f_default.png') : require('./m_default.png');

            setLoading(false);
            setAppointmentData(res);
          });
        } else {
          console.warn(response);
          setLoading(false);
          localStorage.removeItem('@uuid');
          history.push('/not_found');
        }
      })
      .catch(() => {
        localStorage.removeItem('@uuid');
        history.push('/not_found');
      });

    return;
  }, [loading, appointmentData, baseURL, uuid, history]);

  /**
   * Remove token from localStorage as soon as page is called,
   * preventing user to go back to video room
   */
  useEffect(() => {
    localStorage.removeItem('@token');
  }, []);

  /**
   * Fetching appointment data
   */
  useEffect(() => fetchAppointmentData(), [fetchAppointmentData]);

  /**
   * Remove uuid agenda id from localStorage
   * as soons as appointment data are fetched
   */
  useEffect(() => {
    if (appointmentData) {
      localStorage.removeItem('@uuid');
    }
  }, [appointmentData]);

  /**
   * Check if has image on S3. Default image is shown is this meanwhile.
   */
  useEffect(() => {
    if (!appointmentData || loading) return;
    if (appointmentData.doctorProfilePicture) {
      fetch(appointmentData.doctorProfilePicture)
        .then(res => res.status === 200 && setHasProfilePicture(true))
        .catch(() => {});
    }
  }, [appointmentData, loading]);

  if (loading || !appointmentData) {
    return (
      <ThemeProvider theme={theme}>
        <main className={classes.container}>
          {/* Top Header */}
          <header className={classes.header}>
            <img src={logoConecta} alt="logo" />
          </header>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress className={classes.loadingSpinner} />
          </div>
        </main>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <main className={classes.container}>
        {/* Top Header */}
        <header className={classes.header}>
          <img src={partnerData ? partnerData.logo_url : logoConecta} alt="logo" />
        </header>

        {/* Gradient Banner */}
        <div className={classes.banner}>
          <h1>Consulta finalizada</h1>
          <p>{appointmentData.stringDate}</p>
        </div>

        {/* Content */}
        <div className={classes.content}>
          {/* Doctor Card */}
          <div className={classes.doctorCard}>
            <img
              className={classes.doctorCardImage}
              src={hasProfilePicture ? appointmentData.doctorProfilePicture : appointmentData.defaultProfilePicture}
              alt="doctor-profile"
            />
            <div className={classes.doctorCardData}>
              <p className="name">
                {' '}
                {appointmentData.doctor.user.gender === 'M'
                  ? `Dr. `
                  : appointmentData.doctor.user.gender === 'F'
                  ? `Dra. `
                  : null}{' '}
                {appointmentData.doctor.user.name}
              </p>
              {appointmentData.medical_specialty && (
                <p className="specialty">{appointmentData.medical_specialty.name}</p>
              )}
              <p className="address"> {appointmentData.doctor.crm_regional} </p>
              {appointmentData.doctor.is_amb_especialization && <div className="specialty-label">ESPECIALISTA</div>}
              <p className="crm">
                CRM {appointmentData.doctor.crm}/{appointmentData.doctor.crm_regional}
              </p>
            </div>
            <div className={classes.doctorCardSpecialty}>
              {appointmentData.doctor.is_amb_especialization && <div className="specialty">ESPECIALISTA</div>}
              <p>
                CRM {appointmentData.doctor.crm}/{appointmentData.doctor.crm_regional}{' '}
              </p>
            </div>
          </div>

          {/* Informations */}
          <div className={classes.information}>
            <p>Obrigado por utilizar o Dr.Conecta.</p>
            <p>Tenha um ótimo dia :)</p>
          </div>

          {/* Action Buttons */}
          <div className={classes.actionButtons}>
            <a
              className={classes.linkButton}
              href={`mailto:${partnerData ? partnerData.contact_email : 'atendimento@drconecta.com.br'}`}
            >
              Precisa de ajuda?
            </a>
          </div>
        </div>
      </main>
    </ThemeProvider>
  );
}
