import React from 'react';
import ReactDOM from 'react-dom';

import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';

import App from './App';
import AppStateProvider, { useAppState } from './state';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { ConnectOptions } from 'twilio-video';
import ErrorDialog from './components/ErrorDialog/ErrorDialog';
import LoginPage from './components/LoginPage/LoginPage';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import theme from './theme';
import './types';
import { VideoProvider } from './components/VideoProvider';
import Swal from 'sweetalert2';

// new pages
import EntryPage from './components/EntryPage';
import FinishPage from './components/FinishPage';
import NotFound from './components/NotFound';
import ConfirmPage from './components/ConfirmPage';
import DoctorPage from './components/DoctorPage/DoctorPage';

// See: https://media.twiliocdn.com/sdk/js/video/releases/2.0.0/docs/global.html#ConnectOptions
// for available connection options.
const connectionOptions: ConnectOptions = {
  bandwidthProfile: {
    video: {
      mode: 'collaboration',
      dominantSpeakerPriority: 'standard',
      renderDimensions: {
        high: { height: 1080, width: 1920 },
        standard: { height: 720, width: 1280 },
        low: { height: 90, width: 160 },
      },
    },
  },
  dominantSpeaker: true,
  maxAudioBitrate: 12000,
  networkQuality: { local: 1, remote: 1 },
  preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
};

const VideoApp = () => {
  const history = useHistory();
  const { error, setError } = useAppState();

  const handleError = () => {
    localStorage.removeItem('@token');
    const uuid = localStorage.getItem('@uuid');
    setError(null);
    history.push(`/login/${uuid}`);
    Swal.fire(
      'Aviso',
      'Não foi possível acessar a sala nesse momento. Por favor, tente novamete. Caso o erro persista, por favor entre em contato conosco.',
      'error'
    );
    return;
  };

  return (
    <VideoProvider options={connectionOptions} onError={setError}>
      <ErrorDialog dismissError={() => handleError()} error={error} />
      <App />
    </VideoProvider>
  );
};

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <Router>
      <AppStateProvider>
        <Switch>
          {/* Private Routes */}
          <PrivateRoute path="/finish" exact>
            <FinishPage />
          </PrivateRoute>
          <PrivateRoute path="/video" exact>
            <VideoApp />
          </PrivateRoute>
          {/* <PrivateRoute path="/room/:URLRoomName">
            <VideoApp />
          </PrivateRoute> */}

          {/* Open Route */}
          <Route path="/entry_page" exact>
            <EntryPage />
          </Route>
          <Route path="/login/:uuid" exact>
            <LoginPage />
          </Route>
          <Route path="/doctor/:token" exact>
            <DoctorPage />
          </Route>
          <Route path="/confirm/:uuid" exact>
            <ConfirmPage />
          </Route>
          <Route path="/not_found">
            <NotFound />
          </Route>
          <Redirect to="/not_found" />
        </Switch>
      </AppStateProvider>
    </Router>
  </MuiThemeProvider>,
  document.getElementById('root')
);
