export const tou = `Considerando que o Governo Federal brasileiro por meio do Ministério da Saúde liberou em caráter excepcional e temporário, as ações de Telemedicina, com o objetivo de regulamentar e operacionalizar as medidas de enfrentamento da emergência de saúde pública de importância internacional previstas na lei nº. 13.989, de 15 de abril de 2020, a plataforma DR. CONECTA apresenta seus termos e condições de uso conforme abaixo.

Para fins do presente instrumento e sem prejuízo de outras nomenclaturas ao longo deste documento, os termos abaixo deverão ser interpretados conforme os significados abaixo.

    "TELEMEDICINA": É o exercício da Medicina com interação à distância, para contemplar atendimento pré-clínico, de suporte assistencial, de consulta, monitoramento e diagnóstico, por meio de tecnologia da informação e comunicação.

    "TELECONSULTA": Modalidade de consulta médica a distância, realizada por profissional médico devidamente inscrito no Conselho Regional de Medicina. 

Os Termos relativos aos serviços de TELECONSULTA da plataforma "DR. CONECTA" estabelecem as condições gerais de uso e são de propriedade da empresa Dr. Conecta Tecnologia em Saúde S.A. 

Ao utilizar os serviços do "DR. CONECTA", o usuário declara sob as penas da Lei que é maior de 18 anos e está de acordo com as disposições deste Termo, considerando-se sempre a versão na data da utilização, podendo este Termo ser modificado a critério do aplicativo "DR. CONECTA" a critério da empresa. O usuário declara ainda, ter ciência e concordar com os TERMOS E CONDIÇÕES GERAIS DE USO DR. CONECTA.DA TELECONSULTA.

O Usuário reconhece e concorda que a plataforma DR. CONECTA é um meio de viabilizar o atendimento médico por meio de "TELECONSULTA", possibilitando ao paciente acesso a profissionais de saúde de forma remota nos moldes do determinado pela portaria Nº 467, DE 20 DE MARÇO DE 2020;

O Usuário reconhece e concorda, que para os fins exclusivos da "TELECONSULTA", poderão ser gravados e armazenados dados de áudio e vídeo do paciente individualmente ou juntamente de seu responsável, não podendo requerer qualquer direito autoral ou de uso de imagem;

O Usuário reconhece e concorda que os dados armazenados poderão ser utilizados somente para fins de "TELECONSULTA", não podendo ser divulgados para outros fins que não os aqui previstos, sem sua expressa autorização;

A "TELECONSULTA" deverá ser previamente agendada, respeitando-se o horário e dia marcado, sendo que o não comparecimento acarretará a cobrança do valor da consulta de acordo com a política do médico;

Usuário concorda que a "TELECONSULTA" é uma modalidade de atendimento remoto utilizado pela plataforma Dr. Conecta para viabilizar o atendimento por meio da "TELEMEDICINA" deforma complementar, não substituindo jamais a consulta presencial, sendo que, em casos de manutenção de sintomas o USUÁRIO deve procurar o atendimento presencial junto a médicos, hospitais ou pronto socorros;

O USUÁRIO reconhece e concorda que é responsável pelas informações prestadas ao profissional médico a distância, sendo que são de sua total responsabilidade eventuais erros na presteza das informações que levarem a diagnósticos imprecisos ou equivocados, ocasião a qual, o médico poderá encerrar a "TELECONSULTA" encaminhando o paciente para a consulta presencial;

O USUÁRIO deverá comunicar imediatamente à administração da plataforma, toda e qualquer atitude que indicar violação aos direitos do paciente ou a legislação;

É vedada a gravação independente de consultas por pacientes ou médicos, sem autorização da plataforma Dr. Conecta, bem como, também é vedada a divulgação destas imagens.

A plataforma Dr. Conecta não se responsabiliza pela qualidade da conexão de internet de médicos e pacientes, uma vez que é condição necessária a "TELECONSULTA".

A plataforma Dr. Conecta se resguarda ao direito de suspender o atendimento da modalidade de "TELECONSULTA" em sua plataforma sem comunicado prévio.

O pagamento pela "TELECONSULTA" seguira a política de pagamento previstas nos demais termos de uso.

Seja bem-vindo em nossa plataforma. 
Atenciosamente, Equipe Dr. Conecta.

Fevereiro, 2021.`;
